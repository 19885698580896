import { useState, useEffect, useRef, useCallback } from "react";
import { Col, Input, Row, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';
import "../assets/css/register.scss";
import { api, attachmentUrl } from "../utils/api";
import { FaSpinner } from "react-icons/fa";
import swal from "sweetalert";
import moment from "moment";

const Member = () => {
    const productPerpage = window.innerWidth > 991 ? 10 : 5;
    const [pageCount, setPageCount] = useState('0');
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [firstLoad, setFirstLoad] = useState(true);
    const [result, setResult] = useState([]);
    const [password, setPassword] = useState('');
    const [authorized, setAuthorized] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['jiuyi-auth']);

    const changePage = (event) => {
        setPageNumber(event);
    }

    useEffect(() => {
        const token = cookies['jiuyi-auth'];
        if (token === true) {
           setAuthorized(true);
           getList();
        } else {
            setAuthorized(false);
            removeCookie('jiuyi-auth', { path: '/' });
        }
    }, []);
    
    const getList = () => {
        setLoading(true);

        fetch(api.listing, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setResult(responseJson.data.data);
                } else {
                    console.log(responseJson.message)
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const submitForm = () => {
        if (password === '88888') {
            getList();
            setAuthorized(true);
            setCookie('jiuyi-auth', true,  { path: '/', maxAge: 1800 });
        } else {
            swal("错误", "密码错误", "error");
        }
    }


    return (
        <>
            <div className='registerImg'>
                <div className='registerBg w-100 d-flex align-items-center justify-content-center'>
                    <div className='container-fluid'>
                        <div className="py-5 agent mb-5 mb-lg-0">
                            <Row className="mx-0 px-0 align-items-center">
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-5 text-center">
                                    <span className="text-white h2">注册名单 {result.length > 0 ? `(${result.length})` : ''}</span>
                                </Col>
                            </Row>

                            {authorized ? (
                                <Row className="mx-0 px-0">
                                    <Col lg={12} md={12} sm={12} xs={12} className="px-md-0">
                                        <div className="py-5 bgWhite">
                                            <div onClick={()=>getList()} className="px-4 py-2">
                                                <span>刷新</span>
                                            </div>
                                            <div className="tableContainer">
                                                <Table responsive className="w-100 mt-0 table-list py-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>中文姓名</th>
                                                            <th>英文姓名</th>
                                                            <th>电子邮件</th>
                                                            <th>手机号码</th>
                                                            <th>学生类型</th>
                                                            <th>何处得知此消息</th>
                                                            <th>好友姓名</th>
                                                            <th>单据</th>
                                                            <th>注册时间</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading ? (
                                                            <tr>
                                                                <td colSpan={10}>
                                                                    <div className="py-5 text-center bg-transparent">
                                                                        <div>
                                                                            <span>加载数据中</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            result.length > 0 ? (
                                                                result.map((item, i) => (
                                                                    <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                        <td className={`tdText`}>{i+1}</td>
                                                                        <td className={`tdText`}>{item.cn_name}</td>
                                                                        <td className={`tdText`}>{item.name}</td>
                                                                        <td className={`tdText`}>{item.email}</td>
                                                                        <td className={`tdText`}>{item.phone}</td>
                                                                        <td className={`tdText`}>{item.type}</td>
                                                                        <td className={`tdText`}>{item.reference}</td>
                                                                        <td className={`tdText`}>{item.referral}</td>
                                                                        <td className={`tdText`}>{item.slip && item.slip[0] ? <a href={attachmentUrl + item.slip[0].webp} target="_blank" style={{ color:'#000'}}>查看</a> : null}</td>
                                                                        <td className={`tdText`}>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={10}>
                                                                        <div className="py-5 text-center bg-transparent">
                                                                            <div>
                                                                                <span>没有数据</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Row className="mx-0 px-0 justify-content-center">
                                        <Col lg={8} md={8} sm={12} xs={12} className='mb-3'>
                                            <Row className="mx-0 bgWhite p-5">
                                                <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                    <div>
                                                        <span className='input-label'>密码 <span className='input-label'>*</span></span>
                                                        <div className={`mt-1`}>
                                                            <Input
                                                                type='password'
                                                                name="password"
                                                                placeholder={'请输入密码'}
                                                                className={`form-control input-transparent py-2 px-0`}
                                                                value={password}
                                                                autoComplete="off"
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <button className="register-btn text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading}>
                                                        {loading ? <FaSpinner className="fa-spin" /> : (
                                                            <div className='d-flex w-100 justify-content-center align-items-center'>
                                                                <span className={`btnText`}>提交</span>
                                                            </div>
                                                        )}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Member;