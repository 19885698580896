import React, { useEffect, useState } from 'react'
import { Col, Input, Row } from 'reactstrap';
import { FaSpinner } from "react-icons/fa";
import "../assets/css/register.scss";
import swal from 'sweetalert';
import { api } from '../utils/api';
import phoneCodeList from '../utils/phoneCode';
import Select from "react-select";
import customSelectStyles from './SelectStyle';

const typeList = [
    { label: '新生', value: '新生' },
    { label: '复习生', value: '复习生' },
]

const newsFromList = [
    { label: '网络', value: '网络' },
    { label: '好友推荐', value: '好友推荐' },
    { label: '内训课程', value: '内训课程' },
]

function Register() {
    const errorObj = {
        cn_name: '',
        name: '',
        email: '',
        phone: '',
        type: '',
        reference: '',
        attachment: '',
    };
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [phoneCode, setPhoneCode] = useState('+60')
    const [formData, setFormData] = useState({
        cn_name: '',
        name: '',
        email: '',
        phone: '',
        type: '新生',
        reference: '',
        attachment: '',
    })
    const [agreeTnc, setAgreeTnc] = useState(false);

    useEffect(() => {
        if (formData.cn_name && formData.name && formData.email && formData.phone && formData.type && formData.reference) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
    }, [formData])

    const handleAddFormChange = (e) => {
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        let newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    function validatePhone(number) {
        // Normalize the number by removing any non-digit characters except the leading +
        const cleanNumber = number.replace(/(?!^\+)\D/g, '');

        // Regular expression for validating Malaysian mobile and landline numbers
        const regex = /^(?:\+?60|\0)?(?:1[0-9]|(?:[123456789][2-9]|3[1-9]|4[1-9]|5[1-9]|6[0-9]|7[1-9]|8[1-9]|9[1-9]))[0-9]{5,8}$/;

        // Test the clean number against the regular expression
        return regex.test(cleanNumber);
    }

    const submitForm = () => {
        let errorMsg = { ...errorObj };
        let check = true;
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

        Object.keys(formData).map((item, i) => {
            if(formData.type === '复习生'){
                if(item !== 'attachment'){
                    if (!formData[item]) {
                        errorMsg[item] = "此项为必填项";
                        check = false;
                    }
                }
            } else {
                if (!formData[item]) {
                    errorMsg[item] = "此项为必填项";
                    check = false;
                }
            }
        })

        if (formData.email) {
            if (!reg.test(formData.email)) {
                errorMsg.email = "电子邮件格式无效";
                check = false;
            }
        }
        if(formData.reference === '好友推荐'){
            if(!formData.referral){
                errorMsg.referral = "此项为必填项";
                check = false;
            }
        }
        // if (formData.phone) {
        //     if (!validatePhone(formData.phone)) {
        //         errorMsg.phone = "手机号码格式无效";
        //         check = false;
        //     }
        // }

        setErrors(errorMsg);
        if (check) {
            if (!agreeTnc) {
                swal("错误", "请阅读并同意以上的资料是正确的。", "error");
            } else {

                let form = new FormData();
                Object.keys(formData).map((item, i) => {
                    if (item === 'attachment') {
                        if(formData.type !== '复习生'){
                            form.append(
                                "attachment",
                                formData[item],
                                formData[item].name
                            );
                        }
                        
                    } else if (item === 'phone') {
                        form.append('phone', phoneCode + formData[item]);
                    } else {
                        form.append(item, formData[item]);
                    }
                })
                setLoading(true);

                fetch(api.register, {
                    method: "POST",
                    body: form
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        if (responseJson.status === "success") {
                            swal("成功", "注册成功", { icon: "success" });
                            resetForm()
                        } else {
                            swal("错误", responseJson.message, "error");
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }

        }
    }

    const resetForm = () => {
        setFormData({
            cn_name: '',
            name: '',
            email: '',
            phone: '',
            type: '',
            reference: '',
            attachment: '',
        })
        setAgreeTnc(false)
    }

    const chooseType = (item, type) => {
        let newFormData = { ...formData };
        newFormData[type] = item;
        // const index = newFormData.student_type.indexOf(item);
        // if (index > -1) {
        //     newFormData.student_type.splice(index, 1);
        // } else {
        //     newFormData.student_type.push(item)
        // }
        setFormData(newFormData)
    }

    const handleOptionSelected = async (e) => {
        const { value } = e;
        setPhoneCode(value)
    }

    const uploadImage = (e, type) => {
        let newFormData = { ...formData };
        newFormData[type] = e.target.files[0];
        setFormData(newFormData)
    }

    return (
        <>
            <div className='bgWhite p-md-5 py-5 px-3'>
                <Row className='px-0 mx-0'>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <span className='form-title'>线上报名</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-5'>
                        <span className='form-description'>请于下列报名表格正确填写您的基本资料</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <div>
                            <span className='input-label'>中文姓名 <span className='text-red'>*</span></span>
                            <div className={`mt-1`}>
                                <Input
                                    type='text'
                                    name="cn_name"
                                    placeholder={'请输入您的中文姓名'}
                                    className={`form-control input-transparent py-2 px-0 ${errors.cn_name ? 'errorBorder' : ''}`}
                                    value={formData.cn_name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                            <div>
                                <span className='text-red'>{errors.cn_name}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <div>
                            <span className='input-label'>英文姓名 <span className='text-red'>*</span></span>
                            <div className={`mt-1`}>
                                <Input
                                    type='text'
                                    name="name"
                                    placeholder={'请输入您的英文姓名'}
                                    className={`form-control input-transparent py-2 px-0 ${errors.name ? 'errorBorder' : ''}`}
                                    value={formData.name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                            <div>
                                <span className='text-red'>{errors.name}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <div>
                            <span className='input-label'>电子邮件 <span className='text-red'>*</span></span>
                            <div className={`mt-1`}>
                                <Input
                                    type='text'
                                    name="email"
                                    placeholder={'请输入您的电子邮件'}
                                    className={`form-control input-transparent py-2 px-0 ${errors.email ? 'errorBorder' : ''}`}
                                    value={formData.email}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                            <div>
                                <span className='text-red'>{errors.email}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <div>
                            <span className='input-label'>手机号码 <span className='text-red'>*</span></span>
                            <div className={`mt-1 position-relative d-flex align-items-bottom w-100`}>
                                <div className='w-50'>
                                    <Select
                                        options={
                                            phoneCodeList.length > 0 ? phoneCodeList.map((item, i) => ({ key: i, value: item.dial_code, label: item.name + " (" + item.dial_code + ")" })) : [{ value: '', label: "没有选项可选" }]
                                        }
                                        placeholder={"选择电话代码"}
                                        className="input-transparent text-capitalize w-100"
                                        value={
                                            phoneCode ? (
                                                phoneCodeList.filter(singleItem => singleItem.dial_code == phoneCode).length > 0 ?
                                                    (phoneCodeList.filter(singleItem => singleItem.dial_code == phoneCode).map((item, i) => ({ value: item.dial_code, label: item.dial_code, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        name="phoneCode"
                                        classNamePrefix="customSelect"
                                        onChange={(e) => handleOptionSelected(e)}
                                    />
                                </div>
                                <Input
                                    type='text'
                                    name="phone"
                                    placeholder={'请输入您的手机号码'}
                                    className={`form-control w-75 input-transparent py-2 px-0 ${errors.phone ? 'errorBorder' : ''}`}
                                    value={formData.phone}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                                {/* <div className='format-container'>
                                    <span className='format'>格式: +60123456789</span>
                                </div> */}
                            </div>
                            <div>
                                <span className='text-red'>{errors.phone}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <div>
                            <span className='input-label'>学生类型 <span className='text-red'>*</span></span>
                            <div className='d-flex align-items-center'>
                                {typeList.map((item, i) => (
                                    <div key={i} className={`d-flex align-items-center cursor-pointer ${i !== 0 ? 'pl-md-4 pl-2' : ''}`} onClick={() => chooseType(item.value, 'type')}>
                                        {formData.type === item.value ? (
                                            <img src={require('../assets/img/checkbox.svg').default} className='img-fluid' alt="checkbox" />
                                        ) : (
                                            <img src={require('../assets/img/uncheckbox.svg').default} className='img-fluid' alt="checkbox" />
                                        )}
                                        <span className='pl-1 referenceLabel'>{item.label}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span className='text-red'>{errors.type}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div>
                            <span className='input-label'>您从何处得知此课程消息 <span className='text-red'>*</span></span>
                            <div className='d-flex align-items-center'>
                                {newsFromList.map((item, i) => (
                                    <div key={i} className={`d-flex align-items-center cursor-pointer ${i !== 0 ? 'pl-md-4 pl-2' : ''}`} onClick={() => chooseType(item.value, 'reference')}>
                                        {formData.reference === item.value ? (
                                            <img src={require('../assets/img/checkbox.svg').default} className='img-fluid' alt="checkbox" />
                                        ) : (
                                            <img src={require('../assets/img/uncheckbox.svg').default} className='img-fluid' alt="checkbox" />
                                        )}
                                        <span className='pl-1 referenceLabel'>{item.label}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span className='text-red'>{errors.reference}</span>
                            </div>
                        </div>
                    </Col>
                    {formData.reference === '好友推荐' ? (
                    <Col lg={12} md={12} sm={12} xs={12} className='mt-3'>
                        <div>
                            <span className='input-label'>好友姓名 <span className='text-red'>*</span></span>
                            <div className={`mt-1`}>
                                <Input
                                    type='text'
                                    name="referral"
                                    placeholder={'请输入您的好友姓名'}
                                    className={`form-control input-transparent py-2 px-0 ${errors.referral ? 'errorBorder' : ''}`}
                                    value={formData.referral}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                            <div>
                                <span className='text-red'>{errors.referral}</span>
                            </div>
                        </div>
                    </Col>
                    ) : null}
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <hr />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                        <div>
                            <Row className='px-0 mx-0'>
                                <Col lg={4} md={4} sm={12} xs={12} className='mb-3'>
                                    <span className='bank-title'>银行户口</span>
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={12} className='px-0'>
                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-1'>
                                        <div className='text-left'>
                                            <span className='bank-label'>银行名字: HONG LEONG BANK</span>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-1'>
                                        <div className='text-left'>
                                            <span className='bank-label'>户口号码: 22900027497</span>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-1'>
                                        <div className='text-left'>
                                            <span className='bank-label'>户口持有人: Jiu Yi International Academy Sdn Bhd </span>
                                        </div>
                                    </Col>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <hr />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Row className='px-0 mx-0'>
                            {/* <Col lg={12} md={12} sm={12} xs={12} className='text-right'>
                                <span className='price'>RM 1,680 + 8% SST</span>
                            </Col> */}
                            {/* <Col lg={12} md={12} sm={12} xs={12} className='text-right'>
                                <span className='total-price' style={{ width: '100px'}}>原价： </span>
                                <span className='total-price' style={{ textDecoration: 'line-through'}}>RM 1,680</span>
                                <span className='total-price d-block'>优惠价格：RM 430</span>
                                <span className='small d-block'>(包含午餐和茶点)</span>
                            </Col> */}
                            <Col lg={12} md={12} sm={12} xs={12} className='text-right'>
                                <div className='d-flex align-items-center w-100 justify-content-end'>
                                    <div>
                                        <span className='total-price' style={{ width: '100px'}}>价格： </span>
                                        {formData.type === '复习生' ? (<span className='total-price'>免费</span>) : (<span className='total-price'>RM 1,680</span>)}
                                        
                                    </div>
                                    {formData.type === '复习生' ? null : (
                                    <span className='d-block ml-2' style={{ fontWeight: '900'}}>(终身免费复习)</span>
                                    )}
                                </div>
                                <span className='small d-block'>(包含午餐和茶点)</span>
                                
                            </Col>
                        </Row>
                        {formData.type === '复习生' ? null : (
                        <>
                        <label for="attachment" className={`grey-container position-relative w-100 p-3 mt-3 ${errors.attachment ? 'errorBorderFile': ''}`}>
                            <div style={{ width: '70%' }} className='pe-3'>
                                {formData.attachment ? (
                                    <span className='upload-text'>{formData.attachment.name}</span>
                                ) : (
                                    <span className='upload-text'>请记得上传您的付款单据，以便我们继续处理您的订单。</span>
                                )}

                            </div>
                            <div className='btn-gold p-2 p-md-3 h-100 d-flex align-items-center justify-content-center' style={{ width: '30%' }}>
                                <span className='btn-text'>上传单据</span>
                            </div>
                            <input type='file' id="attachment" className="d-none" onChange={(e) => uploadImage(e, "attachment")} />
                        </label>
                        <div>
                            <span className='text-red'>{errors.attachment}</span>
                        </div>
                        </>
                        )}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <hr />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <div className='d-flex align-items-center cursor-pointer' onClick={() => setAgreeTnc(!agreeTnc)}>
                            {agreeTnc ? (
                                <img src={require('../assets/img/checkbox.svg').default} className='img-fluid' alt="checkbox" />
                            ) : (
                                <img src={require('../assets/img/uncheckbox.svg').default} className='img-fluid' alt="checkbox" />
                            )}
                            <span className='pl-2 tncLabel'>我已阅读并同意以上的资料是正确的。</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {disabledBtn ? (
                            <button className="disabled-btn text-center mx-auto w-100">
                                <div className='d-flex w-100 justify-content-center align-items-center'>
                                    <span className={`btnText`}>注册</span>
                                </div>
                            </button>
                        ) : (
                            <button className="register-btn text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <FaSpinner className="fa-spin" /> : (
                                    <div className='d-flex w-100 justify-content-center align-items-center'>
                                        <span className={`btnText`}>注册</span>
                                    </div>
                                )}
                            </button>
                        )}

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Register