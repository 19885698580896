import React from 'react'
import { Row, Col } from 'reactstrap'
import Register from './Register'

function index() {
    return (
        <>
            <div className='registerImg'>
                <div className='registerBg w-100 d-flex align-items-center justify-content-center'>
                    <div className='container'>
                        <Row className='px-0 mx-0'>
                            <Col lg={6} md={6} sm={12} xs={12} className="order-2 order-md-1 py-5">
                                <Register />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="order-1 order-md-2">
                                <Row className='px-0 mx-0 py-md-5 justify-content-center h-100 mt-3 mt-md-0'>
                                    <Col lg={12} md={12} sm={12} xs={12} className='px-md-3 px-0 pt-5'>
                                        <Row className='px-0 mx-0'>
                                            <Col lg={12} md={12} xs={12} sm={12} className='text-md-left text-center mb-3 mb-md-2'>
                                                <img src={require('../assets/img/logo/logo.webp')} className='logo' alt="logo" />
                                            </Col>
                                            <Col lg={12} md={12} xs={12} sm={12} className='mb-3 mb-md-2 text-md-left text-center'>
                                                <span className='big-title d-block'>解码自己</span>
                                                <span className='big-title d-block'>能量觉醒</span>
                                            </Col>
                                            <Col lg={12} md={12} xs={12} sm={12} className='mb-md-3 mb-4'>
                                                <div className='position-relative text-md-left text-center'>
                                                    <span className='small-title pr-md-3 d-md-inline-block d-block'>掌握生命密码</span>
                                                    <span className='small-title'>创造品牌人生</span>
                                                    <img src={require('../assets/img/sparkleLine.png')} className='sparkle-border' alt="border" />
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                                <Row className='px-0 mx-0'>
                                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                        <div className='text-center text-md-left'>
                                                            <span className='event-label'>地点 Venue:</span>
                                                            <span className='event-value'>D Rapport Residence, Kuala Lumpur</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                        <div className='text-center text-md-left'>
                                                            <span className='event-label'>日期 Date:</span>
                                                            <span className='event-value'>2024年 8月 17日 (星期六)</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                        <div className='text-center text-md-left'>
                                                            <span className='event-label'>时间 Time:</span>
                                                            <span className='event-value d-block'>10:00am - 12:30pm</span>
                                                            <span className='event-value d-block'>02:00pm - 07:00pm</span>
                                                        </div>
                                                    </Col>
                                                    {/* <Col lg={6} md={6} sm={12} xs={12} className='mb-3'>
                                                        <div className='text-center text-md-left'>
                                                            <span className='event-label'>着装要求 Dress Code:</span>
                                                            <span className='event-value'>Smart Casual</span>
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index