const url = "https://jiuyi-api.ithouz.com"; //testnet
const attachmentUrl = "https://jiuyi-api.ithouz.com/uploads/";

// const url = 'https://api.jiuyiacademy.com' //production
// const attachmentUrl = "https://api.jiuyiacademy.com/uploads/";

const api = {
    register: url + '/register',
    listing: url + '/listing',

}

export { url, api, attachmentUrl };